<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50"
      ></div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-40 md-size-50"
      >
        <div class="crud-add-button">
          <div>
            <router-link
              :to="{ name: 'slotEventListenerCreate', params: $route.params }"
            >
              <span class="material-icons"> add_circle </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <datatables-wrapper
      @optionsChanged="getItems"
      @updateTable="getItems"
      :loading="loading"
      :data="data"
      :headers="headers"
      filterType="simple"
      :parameters="['name']"
      @rowClick="rowClick"
    />
  </div>
</template>

<script>
import DatatablesWrapper from "@/components/Datatables/DatatablesWrapper.vue";
export default {
  name: "slot-listeners-crud",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  components: {
    DatatablesWrapper,
  },
  data() {
    return {
      data: {},
      loading: false,
      headers: [
        {
          text: "Nombre del observador",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Tipo de observador", value: "listenerType" },
        { text: "Timestamp", value: "timestamp" },
      ],
    };
  },
  methods: {
    rowClick(event) {
      this.$router.push({
        name: "slotEventListenerEdit",
        params: { ...this.$route.params, eventListener: event.id },
      });
    },
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("listener/getSlotListeners", {
          ...this.$route.params,
          options,
        })
        .then((data) => (this.data = data))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getItems({ page: 1 });
  },
};
</script>